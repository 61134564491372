import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style.min.css";
import "./assets/fonts/stylesheet.css";
import ThemeProvider from "./providers/ThemeProvider";
import { NotificationContainer } from "react-notifications";
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<React.StrictMode>
    <I18nextProvider i18n={i18n}>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </I18nextProvider>
    <NotificationContainer />
</React.StrictMode>);