import React from "react";

const Menu = () => {
    return (
        (<svg
            width="1em"
            height="1em"
            viewBox="0 0 26 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect y="14" width="26" height="2" rx="1" fill="currentColor" />
            <rect y="7" width="26" height="2" rx="1" fill="currentColor" />
            <rect width="26" height="2" rx="1" fill="currentColor" />
        </svg>)
    );
};

export default Menu;