import React from "react";

const ServiceIcon = () => {
    return (
        (<svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 6L7.5 8L5 10"
                stroke="currentColor"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M8.5 10H11"
                stroke="currentColor"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M13.4688 3H2.53125C2.23785 3 2 3.23785 2 3.53125V12.4688C2 12.7622 2.23785 13 2.53125 13H13.4688C13.7622 13 14 12.7622 14 12.4688V3.53125C14 3.23785 13.7622 3 13.4688 3Z"
                stroke="currentColor"
                strokeWidth="0.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>)
    );
};

export default ServiceIcon;