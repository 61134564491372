import React from 'react';

const Logo = (props) => {
  return (
    <svg className={props.className} width="71" height="28" viewBox="0 0 71 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.2945 21.3935C18.4807 22.5092 16.3854 23.1025 14.2448 23.1025L10.6697 23.1025L10.6697 18.3858H14.2448C15.5683 18.3858 16.8621 17.9975 17.9626 17.2699C18.9721 16.6025 19.7773 15.6771 20.2945 14.5947V21.3935Z" fill="url(#paint_linear_354_796)"/>
      <path d="M28.5723 21.6603C28.5723 24.327 29.3423 25.4971 31.8449 25.4971L33.9899 22.8032C32.4224 22.6399 32.0374 22.4494 32.0374 21.6603V3.91846H28.5723V21.6603Z" fill="currentColor"/>
      <path d="M41.211 9.36075C38.4884 9.36075 36.3159 10.2043 35.3258 11.8098L38.0209 13.5241C38.5159 12.6261 39.671 12.0819 41.156 12.0819C42.8611 12.0819 43.9611 12.4901 43.9611 14.1227V14.7486L40.991 15.0207C37.5534 15.3745 35.1058 16.2725 35.1058 19.7827C35.1058 22.5311 36.9209 24.1093 39.836 24.1093C41.376 24.1093 42.8611 23.5107 44.2361 22.259V23.7012H47.4262V14.2316C47.4262 10.4764 45.2261 9.36075 41.211 9.36075ZM38.6809 19.7283C38.6809 17.9596 40.221 17.5786 42.201 17.3881L43.9611 17.2249V19.8916C43.3561 20.3814 42.7786 20.7351 42.1735 21.0072C41.5685 21.2794 41.046 21.3882 40.6335 21.3882C39.396 21.3882 38.6809 20.98 38.6809 19.7283Z" fill="currentColor"/>
      <path d="M52.9171 4.21778C51.5696 4.21778 51.0195 4.68038 51.0195 5.95932C51.0195 7.21104 51.5421 7.70085 52.9171 7.70085C54.2371 7.70085 54.8696 7.29268 54.8696 5.95932C54.8696 4.65317 54.2646 4.21778 52.9171 4.21778ZM51.212 23.7012H54.6771V9.76892H51.212V23.7012Z" fill="currentColor"/>
      <path d="M66.6009 23.7012H70.6985L65.8308 16.6262L70.671 9.76892H66.8484L63.9608 14.1772L61.1557 9.76892H57.1131L61.8982 16.8167L57.0306 23.7012H60.8807L63.7958 19.2657L66.6009 23.7012Z" fill="currentColor"/>
      <path d="M17.9626 17.2699C17.5841 17.5201 17.1828 17.7302 16.7652 17.8982V22.8248C18.1306 22.5202 19.4352 21.969 20.6109 21.1917C22.4952 19.9458 23.9639 18.175 24.8312 16.1033C25.6985 14.0315 25.9254 11.7518 25.4833 9.55242C25.0411 7.35305 23.9498 5.33279 22.3473 3.74713C20.7448 2.16147 18.703 1.08162 16.4803 0.644133C14.2575 0.20665 11.9536 0.431182 9.85976 1.28934C7.76597 2.14749 5.97637 3.60072 4.71728 5.46526C3.45818 7.3298 2.78615 9.52191 2.78615 11.7644L7.55294 11.7644C7.55294 10.4548 7.94541 9.17459 8.68072 8.08569C9.41603 6.9968 10.4612 6.14812 11.6839 5.64695C12.9067 5.14579 14.2522 5.01467 15.5503 5.27015C16.8484 5.52565 18.0408 6.15628 18.9766 7.0823C19.9125 8.00833 20.5499 9.18816 20.8081 10.4726C21.0663 11.757 20.9337 13.0884 20.4273 14.2983C19.9208 15.5082 19.0631 16.5423 17.9626 17.2699Z" fill="currentColor"/>
      <path d="M0.173096 14.7117L7.68994 14.7119V27.2745H2.92314V18.8992H0.173096V14.7117Z" fill="currentColor"/>
      <defs>
        <linearGradient id="paint_linear_354_796" x1="16.5475" y1="20.0639" x2="11.013" y2="20.0684" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="currentColor"/>
          <stop offset="1" stopColor="transparent"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;